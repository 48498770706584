.flex-container {
  display: flex;
  font-weight: 100;
}

.aggridReport {
  margin-top: 1em;
}

.accordionNode {
  margin-bottom: 5px;
  background-color: #f7f7fc;
  border-radius: 10px;
  position: relative;
}

.acoordionOptionArrow img {
  height: 100%;
}

.report-title > div > h3 {
  font-size: 1.1em;
}

.report-title:hover {
  color: #4a22d4;
}

.report-title-det {
  font-weight: 500 !important;
  font-size: 1.1em !important;
}

.acoordionOptionArrow {
  color: #4a22d4;
}

.arrowUp {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  top: 22px;
}

.card-container {
    border-radius: 10px !important;
}

.cardContentRoot {
    padding: 10px !important;
}

.cardContentRoot:last-child {
    padding-bottom: 5px !important
}

.cardHeaderRoot {
    background-color: #131f3e;
    height: 40px;
    color: #00ffff;
}

.cardHeaderRoot > h2 {
    font-size: 16px;
    font-weight: 600;
}

.card-text {
  display: flex;
}

.periodSelectionGenerator {
  width: 35px;
  height: 30px;
  right: 18px;
  top: 10px;
}

.periodSelectionGenerator:hover {
  cursor: pointer;
}

.periodSelectionGenerator img {
  height: 100%;
}

.card-title {
  font-weight: 500;
  font-size: 1em;
  font-family: "Goldplay", sans-serif;
  color: #131f3e;
}

.card {
  border: 1px solid #f2f2f7;
  border-radius: 10px;
}

.card-body hr {
  border-top-color: #f5f6fa;
}

.report-container {
  padding: 10px;
}

.accordionBack{
    background-color: #f5f6fa;
}

.fileColumnIcon {
  width: 20px;
}

.fileColumnIcon:hover {
  cursor: pointer;
}

.form-group {
  margin: 0;
}

.fieldLabel {
  font-weight: 400;
  color: #4327d0;
  font-size: 0.9em;
}

.btn-generate {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
  bottom: 21px;
}

.date-container {
  display: flex;
  margin: 30px 0 0;
  position: relative;
}

.action-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
}

.error-msg {
  display: inline-block;
  color: #fff;
  font-weight: 100;
  padding: 5px 10px;
  background-color: #ea3f2c;
  border-radius: 7px;
  font-size: 0.8em;
  min-width: 200px;
}

.bookTableWrapper .grid-cell-cenLeft {
  margin-left: 0;
}
