body,
html {
    font-family: "Goldplay", sans-serif !important;
    font-size: 14px;
    background: #f6f4fd !important;
}

/* Font imports and settings */
code {
    font-family: "Goldplay", sans-serif;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 10px 10px 15px rgba(74, 34, 212, 0.3);
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(19, 31, 62, 0.8);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(19, 31, 62, 0.9);
}

@font-face {
    font-family: "Goldplay";
    font-style: normal;
    font-weight: 700;
    src: local("Goldplay-Bold"),
        url(../fonts/goldplay/Goldplay-Bold.otf) format("opentype");
}

@font-face {
    font-family: "Goldplay";
    font-style: normal;
    font-weight: 600;
    src: local("Goldplay-SemiBold"),
        url(../fonts/goldplay/Goldplay-SemiBold.otf) format("opentype");
}

@font-face {
    font-family: "Goldplay";
    font-style: normal;
    font-weight: 500;
    src: local("Goldplay-Medium"),
        url(../fonts/goldplay/Goldplay-Medium.otf) format("opentype");
}

@font-face {
    font-family: "Goldplay";
    font-style: normal;
    font-weight: 400;
    src: local("Goldplay-Regular"),
        url(../fonts/goldplay/Goldplay-Regular.otf) format("opentype");
}

@font-face {
    font-family: "Goldplay";
    font-style: normal;
    font-weight: 300;
    src: local("Goldplay-Light"),
        url(../fonts/goldplay/Goldplay-Light.otf) format("opentype");
}

@media screen and (min-width: 600px) {
    .MuiBreadcrumbs-separator {
        margin-left: 40px !important;
        margin-right: 40px !important;
    }
}

p:last-child {
    margin-bottom: 0;
    margin-top: 0;
}

.ButtonReports {
    color: white !important;
    border-radius: 10px !important;
    text-transform: none !important;
    font-size: 1em !important;
}

.ButtonReports:hover {
    background-color: #6e4edd !important;
}

button:focus {
    outline: 0 !important;
}

.btnRoot {
    border-radius: 10px !important;
    margin-right: 20px !important;
    font-size: 1em !important;
}

.btnRoot:hover {
    background-color: #6e4edd !important;
}

.cancelBtn {
    border-radius: 10px !important;
    margin-right: 20px !important;
    border: 2px solid !important;
    font-size: 1em !important;
}

.cancelBtn:hover {
    border: 2px solid #6e4edd !important;
    color: #6e4edd !important;
}

@media screen and (max-width: 1024px) {
    .MuiTable-root {
        table-layout: auto !important;
    }
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 2;
    cursor: pointer;
}

#text {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    color: white;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

/* Login */
@media (min-width: 300px) {
    .login-body {
        background: url(../Imagenes/kiiper-graphic.svg) #131f3e;
        background-position: 50% 75%;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100vh;
    }
}

@media (min-width: 600px) {
    .login-body {
        background: url(../Imagenes/kiiper-graphic.svg) #131f3e;
        background-position: 50% 65%;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100vh;
    }
}

@media (min-width: 1280px) {
    .login-body {
        background: url(../Imagenes/kiiper-graphic.svg) #131f3e;
        background-position: 50% 75%;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100vh;
    }
}

.xero-connect-button {
    text-align: center;
    margin-top: 100px;
    width: 100%;
}

.row {
    margin: 0 !important;
}

.bienvenidos {
    color: #131f3e;
    margin-top: 20px !important;
    font-weight: 700 !important;
    font-size: 2.5em !important;
}

.bienvenidos2 {
    color: #131f3e;
    margin-top: 15px !important;
    font-size: 1.5em !important;
    font-weight: 300 !important;
}

.xeroSyncAnchor2 {
    border-radius: 10px !important;
    font-size: 1.4em !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.xeroSyncAnchor2:hover {
    background-color: #6e4edd !important;
}

.formbutton {
    width: 100%;
    margin-top: 60px;
}

.appLoginHeader {
    padding: 10px 15px;
}

.appLoginHeader img {
    width: 45px;
}

.App-logo {
    animation-name: rotate;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.formularioResp {
    background-color: #ffffff !important;
    position: fixed;
    top: 18%;
    left: 50%;
    margin-top: -5%;
    margin-left: -21.7%;
    width: 38%;
    height: 82%;
    border-radius: 3%;
}

.formulario {
    background-color: #ffffff !important;
    position: absolute;
    top: 0;
    left: -20px;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 32%;
    height: 520px;
    border-radius: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
}

.formulariomain {
    padding: 40px;
    text-align: center;
    border-radius: 3% !important;
}

.sombraslogin {
    box-shadow: 10px 10px 15px rgba(74, 34, 212, 0.3) !important;
}

.kiipFooter {
    margin-top: 90px;
    text-align: left;
}

.formContainer {
    padding: 50px 50px 20px !important;
    height: 100%;
}

.formLoginTitle {
    font-size: 2.2em;
    color: #131f3e;
    font-weight: 500;
    font-family: "Goldplay", sans-serif;
    line-height: 1;
    letter-spacing: 0.374px;
}

.formLoginSubTitle {
    font-size: 1.1em;
    color: #131f3e;
    font-weight: 300;
    margin: 10px 0;
}

.formLoginInstructions {
    font-size: 1em;
    color: #131f3e;
    margin-top: 20px;
    margin-bottom: 30px;
    letter-spacing: -0.408px;
}

.formContainer form label {
    color: #131f3e;
    font-size: 0.9em;
    font-weight: 500 !important;
}

.formContainer form input {
    background-color: #f5f6fa;
    border-radius: 7px;
    height: 35px;
    font-size: 0.9em;
    border-style: none;
    font-weight: 200;
    margin-bottom: 30px;
}

.formContainer form input:focus {
    background-color: #fff !important;
}

.formContainer .form-control:focus {
    box-shadow: 0 0 0 0.1em #4327d0;
}

.formContainer form input:-internal-autofill-selected {
    background-color: #f5f6fa;
}

.formActions {
    padding-top: 20px;
}

/*Botón inicial*/
.xeroSyncAnchor {
    background-color: #131f3e;
    cursor: pointer;
    padding: 10px 30px;
    border: none;
    border-radius: 10px;
    font-size: 0.9em;
    color: #fff;
}

.xeroSyncAnchorClick {
    background-color: #4a22d4;
    padding: 10px 30px;
    border: none;
    border-radius: 10px;
    font-size: 0.9em;
    color: #fff;
}

/** Style of loading */
.divBlocking {
    bottom: 53%;
    left: 42%;
    position: fixed;
    right: 42%;
    top: 47%;
    z-index: 9999;
}

/* Change the value to with anchors hover color*/
#xeroSyncAnchor:hover {
    background-color: #4a22d4;
}

/*Color del header*/
.MuiAppBar-colorPrimary {
    background-color: #131f3e !important;
    color: #00ffff !important;
    box-shadow: none !important;
}

/*Tamaño del contenido*/
.MuiContainer-maxWidthLg {
    max-width: 100% !important;
}

/*Alinea el logo inical de Kipepr*/
.alinearLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 650px;
}

/* Style input checkbox */
input[type="checkbox"] {
    cursor: pointer !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    border-color: #4a22d4 !important;
    border-radius: 4px;
    color: white !important;
    background-color: #4a22d4 !important;
    box-shadow: #4a22d4 !important;
}

/* Labels como link */
.label {
    cursor: pointer;
    color: #4a22d4;
    text-decoration: underline;
}

.label:hover {
    color: #131f3e; /* Change the value to with anchors hover color*/
}

/* Style corrections to be like Recco first design ---------------------------------------------- */

/* Ribbon header container */

@media (max-width: 400px) {
    .MuiToolbar-regular {
        min-height: inherit !important;
        padding: 0 15px !important;
    }
}

@media (min-width: 420px) {
    .MuiToolbar-regular {
        min-height: inherit !important;
        padding: 0 30px !important;
    }
}

/* Logo */
.kiiperLogoText {
    line-height: 1 !important;
    font-weight: 400 !important;
}

/* Ribbon header buttons */
.MuiIconButton-label img {
    height: 17px;
}

/* Menu ribbon */
.bg-light {
    background-color: #fff !important;
}

/* Organization selection */
.navbar-brand {
    padding: 0 25px 0 0 !important;
}

.botBreadcrumb {
    margin-bottom: 15px !important;
}

.rw-dropdown-list-input {
    font-size: 0.8em !important;
}

.rw-state-focus > .rw-widget-container,
.rw-state-focus > .rw-widget-container:hover,
.rw-widget-container.rw-state-focus,
.rw-widget-container.rw-state-focus:hover {
    box-shadow: none !important;
}

/* Nav bar */
.navbar2 {
    padding: 0 !important;
    height: 90px;
    background-color: #fff;
}

#basic-navbar-nav > div > a,
#basic-navbar-nav > div,
#basic-navbar-nav > div > div,
#basic-navbar-nav > div > div > a {
    height: 100%;
    display: flex;
}

/* Nav bar options container */
.navbar-nav,
.navbar-nav div,
.navbar-nav a {
    align-items: center;
    justify-content: center;
}

/* Nav bar option selected */
.navBarOptionSelected {
    color: #4a22d4 !important;
    border-bottom: 2px solid #4a22d4 !important;
}

/* Option selection menu displayed */
.dropdown-menu {
    border: 1px solid #eee !important;
    box-shadow: 0px 8px 16px rgba(35, 44, 81, 0.08) !important;
    border-radius: 8px !important;
}

.navbar-nav .dropdown-menu {
    height: auto !important;
    padding: 0;
}

.dropdown-menu a {
    justify-content: flex-start;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 3px;
}

/* Grid in sells and purchases */

/* UI Menu Tabs */
.ui.menu {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top: 1px solid #e5e5ea !important;
    border-left: 1px solid #e5e5ea !important;
    border-right: 1px solid #e5e5ea !important;
    padding: 0 20px;
    align-items: center;
    box-shadow: none !important;
}

/* UI Menu Tabs label */
.ui.menu .item {
    padding: 20px 14px !important;
    color: rgba(35, 44, 81, 0.6) !important;
    font-weight: 700 !important;
}

/* UI Menu Tab selected */
.ui.menu .active.item {
    background: transparent !important;
    border-bottom: 2px solid #4a22d4;
    border-radius: 0 !important;
}

.ui.menu .item:before {
    background: transparent !important;
}

/** Title module */
h3.MuiTypography-root {
    padding: 0 !important;
    margin: 0;
}

/** Table styles */
tbody:before {
    content: "@";
    display: block;
    line-height: 15px;
    text-indent: -99999px;
}

/** Backdrop styles */
.MuiBackdrop-root {
    background-color: rgb(34, 34, 34, 0.2) !important;
}

/* Error 404 */
.error-body {
    background: url(../Imagenes/crack.png) #131f3e;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100vh;
}
