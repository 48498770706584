.rw-input-reset {
    font-size: 1rem;
    font-weight: 100;
}

.moduleTitle {
    display: inline-block;
}

.moduleTitle > h3 {
    font-weight: 600 !important;
    color: #9680ed !important;
    font-size: 18px !important;
}

.rw-popup {
    box-shadow: 0px 4px 8px rgba(35, 44, 81, 0.08);
    border-style: none;
    border-radius: 8px;
    padding: 5px;
    margin-top: 15px;
}

.rw-filter-input .rw-select,
.rw-filter-input .rw-btn {
    background: transparent !important;
}

.rw-popup .rw-btn {
    margin-bottom: 10px;
}

.rw-popup .rw-list-option {
    border-radius: 8px;
    padding: 7px !important;
    border-style: none;
}

.rw-popup .rw-list-option > span {
    display: flex;
    align-items: center;
}

.rw-popup .rw-list-option > span strong {
    margin-right: 10px;
    margin-bottom: 2px;
}

.rw-list-option:hover,
.rw-list-option:hover.rw-state-focus {
    background-color: transparent;
    border-style: none;
    color: #131f3e;
}

/* asigna el tamaño de la lista desplegable*/
.rw-list {
    font-size: 0.8rem;
    max-height: 300px;
}

/* asigna color y borde del dllprincipal */
.rw-widget > .rw-widget-container {
    border-radius: 10px !important;
    height: 40px !important;
    padding: 4px;
    background-color: #131f3e !important;
    color: white !important;
    border-color: #131f3e !important;
}

.rw-list-option.rw-state-selected,
.rw-list-option.rw-state-selected:hover {
    background-color: #e6defd !important;
    color: #5540c2 !important;
}

.rw-input {
    color: white !important;
}

.rw-filter-input .rw-select,
.rw-filter-input .rw-btn {
    color: black !important;
    background-color: #eceded;
}

.icon-text-size {
    height: 25px;
    margin-right: 12px;
}

/* asigna estilo del input */
.rw-filter-input {
    color: #131f3e !important;
    background-color: #f5f6fa;
    border-radius: 8px;
    height: 30px;
    border-style: none;
    box-shadow: none;
}

.rw-btn {
    color: white !important;
}

.navMenuKiiper {
    display: flex;
    flex-direction: row;
    padding-left: 40px !important;
    z-index: 1;
}

.navMenuKiiper > a {
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    width: 160px;
}

.navMenuKiiper > a > div > span {
    font-size: 16px;
    font-weight: 600;
}

.navMenuKiiper > a:hover {
    color: #5540c2 !important;
}

@media (min-width: 150px) {
    .ddlkiiper {
        width: 150px;
        font-size: 1em;
    }
}

@media (min-width: 350px) {
    .ddlkiiper {
        width: 200px;
        font-size: 1.4em;
    }
}

@media (min-width: 600px) {
    .ddlkiiper {
        width: 240px;
        font-size: 1.4em;
    }
}

.submenuKiiper {
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #131f3e !important;
}

.submenuKiiper > div > span {
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #131f3e !important;
}

.submenuKiiper:hover {
    color: #5540c2 !important;
    background-color: #e6defd !important;
}

#navbardown {
    min-height: 48px !important;
}

#basic-navbar-nav {
    color: #131f3e !important;
    font-size: 10pt !important;
    height: 100%;
}

.rw-list-empty,
.rw-list-option,
.rw-list-optgroup {
    padding-top: 10px !important;
    padding-bottom: 7px !important;
}

#basic-navbar-nav a:hover,
#basic-navbar-nav a.active {
    color: #5540c2 !important;
}

.dropdown-item {
    color: #131f3e !important;
    font-size: 10pt !important;
    border-color: #131f3e !important;
}

.dropdown-item.active,
.dropdown-item:active {
    border-color: #5540c2 !important;
    background-color: #e6defd !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: #e6defd;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-top: 8px !important;
    margin-left: 29px !important;
}

.dropdown-item,
.dropdown-item {
    border-color: #5540c2 !important;
    font-size: 10pt !important;
    width: 100%;
}

.navbar-light .navbar-nav .nav-link {
    font-size: 11pt;
    font-weight: bold;
}

@media (min-width: 300px) {
    .mainPanel {
        padding-top: 30px;
        padding-bottom: 20px;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

@media (min-width: 600px) {
    .mainPanel {
        padding-top: 30px;
        padding-bottom: 40px;
        padding-left: 40px !important;
        padding-right: 40px !important;
    }
}

.breadcrumbGroup {
    padding-top: 15px;
    padding-bottom: 15px;
}

/* estilo del boton que se encuentra a un lado delddl principal */
.ButtonBase-navbar > button {
    border-radius: 10px !important;
    border-style: solid !important;
    color: #b6c4ec !important;
    font-size: xx-large !important;
}

.gobackicon {
    border: 1px solid #e6defd !important;
    border-radius: 15px !important;
}

.gobackicon path {
    color: #4a22d4 !important;
}

.gobackicon:hover {
    background-color: #e6defd !important;
}

/* Notification icon (bell) style */
.MuiBadge-colorSecondary {
    color: #131f3e !important;
    background-color: #5af2e8 !important;
    border: 1px solid #131f3e;
    font-weight: bold !important;
}

/* Notification number location from notification icon (bell) from menu */
.MuiBadge-anchorOriginTopRightRectangle {
    transform: scale(1) translate(58%, -17%) !important;
}

@media screen and (min-width: 600px) {
    .avatarOptions {
        position: absolute;
        background-color: #fff;
        top: 50px;
        right: 0;
        box-shadow: 10px 10px 15px rgba(74, 34, 212, 0.3);
        width: 300px;
        border-radius: 10px;
    }
}

@media screen and (max-width: 600px) {
    .avatarOptions {
        position: absolute;
        background-color: #fff;
        top: 50px;
        right: 0;
        box-shadow: 10px 10px 15px rgba(74, 34, 212, 0.3);
        width: 285px;
        border-radius: 10px;
    }
}

.avatarInfo {
    border-bottom: 1px solid #e7e7eb;
    padding: 25px 25px 10px;
}

.avatarInfoText {
    margin: 10px 0;
}

.avatarInfoName {
    text-transform: uppercase;
}

.avatarActionsList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.avatarActionListOption {
    padding: 10px;
    text-align: left;
}

.avatarActionListOption:hover {
    background-color: #e6defd;
}

.avatarActionListOption img {
    height: 10px;
}

.avatarActionListOption span {
    margin-left: 10px;
}

.parent {
    height: 380px;
    display: flex;
}
.parent2 {
    height: 450px;
    display: flex;
}
.child {
    height: 40px;
    width: 100%;
    align-self: flex-end;
}

#updateIcon {
    cursor: pointer;
}
