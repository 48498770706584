/*Botón Generar*/
#xeroGenerate {
	background-color: #131f3e;
	cursor: pointer;
	padding: 10px 20px;
	border: none;
	border-radius: 10px;
	font-size: 0.9em;
	width: 110px;
	color: white;
}

.xeroGenerate {
	background-color: #131f3e;
	cursor: pointer;
	padding: 10px 20px;
	border: none;
	border-radius: 10px;
	font-size: 0.9em;
	width: 110px;
	color: white;
	font-weight: 100;
}

#xeroGenerate:hover {
	background-color: #4a22d4; /* Change the value to with anchors hover color*/
	color: white;
}

.xeroGenerate:hover {
	background-color: #4a22d4; /* Change the value to with anchors hover color*/
	color: white;
}

.xeroGenerate:active,
.xeroGenerate:focus {
	background-color: #9680ed !important; /* Change the value to with anchors hover color*/
	border-style: none !important;
}

/*DropDown de los periodos*/
#ddlPeriodo,
.ddlPeriodo {
	width: 200px;
	border-radius: 7px;
	border-style: none;
	color: rgba(35, 44, 81, 0.6);
	border-width: thin;
	font-size: 0.9em;
	box-sizing: border-box;
	font-weight: 400;
	background-color: #f5f6fa;
}

.ddlPeriodo:active, .ddlPeriodo:focus {
  border-style: none!important;
}

/*txtDate de los periodos*/
.calendar {
	width: 190px;
	border-radius: 7px;
	border-style: none;
	color: rgba(35, 44, 81, 0.6);
	cursor: pointer;
	border-width: thin;
	font-size: 0.9em;
	padding-left: 5px;
	background-color: #f5f6fa;
	padding: 9px;
}

/*txtDate de los periodos*/
.calendarDueDate {
	width: 400px;
	height: 30px;
	border-radius: 7px;
	border-color: #4a22d4;
	color: #131f3e;
	cursor: pointer;
	border-width: thin;
	font-size: 13px;
	padding-left: 5px;
}

.MuiDialog-paperScrollPaper {
	width: 450px;
	/*height: 60%;*/
}

/*Color selected date in calendar*/
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
	border-color: #4a22d4 !important;
	background-color: #e6defd !important;
	color: #212529 !important;
	border-style: ridge !important;
	border-width: thin;
	font-weight: lighter;
	border-radius: 3px !important;
	padding: 2px 6px 2px 6px;
	width: 30px;
}

/*Color selected date in calendar on load*/
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
	border-color: #4a22d4 !important;
	background-color: #e6defd !important;
	color: #212529 !important;
	border-style: ridge !important;
	border-width: thin;
	font-weight: lighter;
	border-width: thin;
	border-radius: 3px !important;
	padding: 2px 6px 2px 6px;
	width: 30px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	border-color: #f2f2f2;
	background: #fcfcfc;
	border-radius: 3px;
	color: #6c757d;
	font-weight: lighter;
	padding: 2px 6px 2px 6px;
	width: 30px;
}

.react-datepicker__header {
	border-color: #f2f2f2;
	background: #fcfcfc;
	border-radius: 3px;
	color: #6c757d;
	font-weight: lighter;
	padding: 2px 6px 2px 6px;
	font-size: 13px;
}
