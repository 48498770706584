#overlay {
  zoom: 1;
  filter: alpha(opacity=50);
  opacity: 0.5;
  z-index: -1;
  position: relative;
}

#overlayLF {
  zoom: 1;
  filter: alpha(opacity=50);
  opacity: 0.5;
  z-index: -1;
  position: relative;
}

.ButtonDisabled {
  border-radius: 10px !important;
  text-transform: none !important;
  font-size: 1em !important;
  background-color: #dbd3f6 !important;
  color: #fff !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

@media screen and (max-width: 600px) {
  .Button {
    border-radius: 10px !important;
    text-transform: none !important;
    font-size: 1em !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    margin-left: 5px !important;
  }
  .ButtonDisabled {
    border-radius: 10px !important;
    text-transform: none !important;
    background-color: #dbd3f6 !important;
    color: #fff !important;
    font-size: 1em !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    margin-left: 5px !important;
  }
}

.Button {
  border-radius: 10px !important;
  text-transform: none !important;
  font-size: 1em !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.Button:hover {
  background-color: #6e4edd !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #4a22d4 !important;
}
