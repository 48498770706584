/**
 * Filename: AdminPanel.css
 * Author: jafb717@gmail.com
 * Description: Admin panel css stylesheet
 */

/* General styles ----------------------------------------------------- */

.makeStyles-content-12 {
    height: 100% !important;
}

.ddlAdminPanel {
    font-weight: 500 !important;
    font-size: 1em !important;
}

.TableBanks {
    max-width: 900px;
    padding-top: 25px;
    margin: 0 auto;
}

/* Admin panel styles ------------------------------------------------ */

/** Admin panel container to center content */
.centeredContainer {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
}
@media screen and (min-width: 600px) {
    /** Admin panel title */
    #adminPanelTitle {
        padding: 40px;
    }
}
@media screen and (max-width: 600px) and (min-width: 300px)  {
    /** Admin panel title */
    #adminPanelTitle {
        padding: 40px 20px 20px 20px;
    }
}
/* Admin breadcrumb styles --------------------------------------- */

/** Admin panel header breadcrumb root */
#adminBreadcrumbRoot {
    color: #9680ed;
    font-size: 18px;
    font-weight: 600;
}

#adminBreadcrumbRoot:hover {
    cursor: pointer;
}

/* Admin body styles ------------------------------------------------ */

/** Admin panel body */
#adminPanelBody {
    padding: 1em 2em 1em 2em;
}

/* Topics section styles ---------------------------------------------- */

/** Topics panel title */
.topicsPanelTitle {
    font-family: "Goldplay", sans-serif;
    font-weight: 500;
    margin-left: 15px;
    margin-bottom: 10px;
    display: block;
}

/** Topic item */
.topicItem {
    padding: 1.5em;
    border: 1px solid #d9d9d9;
    margin: 5px 0;
    border-radius: 10px;
}

/** Topic item hover effect */
.topicItem:hover {
    border-radius: 10px;
    background-color: #f8f9fa;
    cursor: pointer;
}

/** Topic item icon */
.topicItem img {
    float: left;
    width: 40px;
    margin-right: 20px;
}

/** Topic item title */
.topicItemTitle {
    font-family: "Goldplay", sans-serif;
    display: block;
    font-weight: 500;
}

/** Topic item resume */
.topicItemResume {
    font-weight: 100;
}

/* Settings section styles -------------------------------------------- */

/** Settings panel */
.settingsPanel {
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

/** Settings panel form */
.settingsPanelForm {
    padding: 1em 2em 2em;
}

/** Settings panel form select */
.settingsPanelForm select {
    padding: 0.375rem 0.75rem;
    margin-right: 20px;
}

/** Settings panel form checkbox */
.settingsPanelForm input[type="checkbox"] {
    margin-left: 20px;
}

/** Settings panel form input and select */
.settingsPanelForm select,
.settingsPanelForm input {
    height: 35px;
}

/** Settings panel form input and textarea */
.settingsPanelForm input,
.settingsPanelForm textarea {
    padding: 0.375rem 0.75rem;
}

/** Settings panel form inputs */
.settingsPanelForm input[type="text"],
.settingsPanelForm input[type="password"],
.settingsPanelForm input[type="email"],
.settingsPanelForm select,
.settingsPanelForm textarea {
    border-style: none;
    border-radius: 7px;
    background-color: #f5f6fa;
    color: #131f3e;
    font-size: 0.8em;
    font-weight: 300;
}

/** Form group field container and subgroup field container*/
.settingsPanelForm
    > div.formGroup:nth-last-child(2)
    > .formFieldContainer:last-child,
.settingsPanelForm
    div.formSubGroup:last-child
    > .formFieldContainer:last-child {
    border-bottom-style: none;
}

/** Form group */
.settingsPanel .formSubGroup {
    flex: 1;
}

/** Form group title */
.settingsPanel .formGroupTitle {
    font-family: "Goldplay", sans-serif;
    font-weight: 500;
    margin: 2em 0;
    color: #4a22d4;
}

.formFieldContainer2 {
    display: flex;
    padding: 0.7em 0;
}

.settingsPanel .formFieldContainer2 > div.formFieldItem {
    flex: 1;
    display: flex;
    align-items: center;
}

/** Form field container */
.settingsPanel .formFieldContainer {
    padding: 0.7em 0;
    border-bottom: 0.5px solid #e8e8e8;
}

.taxObligation {
    border-bottom: 0.5px solid #e8e8e8;
}

/** Form field label */
.settingsPanel .formFieldLabel label {
    font-weight: 400 !important;
    font-size: 1em;
    margin-bottom: 0;
}

/* Toggle switch styles ---------------------------------------------- */

/** Toggle switch */
.settingsPanel .sc-AxjAm {
    height: 20px !important;
    width: 40px !important;
    cursor: pointer;
}

/** Toggle switch little ball */
.settingsPanel .sc-AxjAm:after {
    height: 15px !important;
    width: 15px !important;
}

/** Dropdownlist with input from React-widget */

.settingsPanel .rw-widget > .rw-widget-container {
    background-color: #f5f6fa !important;
    color: #131f3e !important;
    border-style: none;
    box-shadow: none;
}

.settingsPanel .rw-input,
.settingsPanel .rw-btn {
    color: #131f3e !important;
}

/* Form actions styles ----------------------------------------------- */

/** Form actions */
#settingsSection .formActions {
    max-width: 700px;
    margin: 0 auto;
    border-top: 1px solid #e6defd;
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

/** Form actions */
#settingsSection .formActions button {
    padding: 10px 30px;
    border-radius: 10px;
    border-style: none;
    outline: none;
    margin-left: 1em;
    font-size: 0.9em;
}

/** Form actions buttons hover effect*/
#settingsSection .formActions button:hover {
    cursor: pointer;
}

/** Form confirm button hover effect*/
#settingsSection .formConfirmButton:hover {
    background-color: #6e4edd;
}

/** Form cancel button */
#settingsSection .formCancelButton {
    border-radius: 10px !important;
    text-transform: none !important;
    border: 2px solid !important;
}

/** Form cancel button hover effect*/
#settingsSection .formCancelButton:hover {
    color: #6e4edd;
    border: 2px solid #6e4edd;
}

/** Horizontal fields group container */
.formHorizontalGroupFields {
    flex-direction: row;
    padding: 1.5em 0.7em;
}

.formFieldItem3 > div {
    background-color: #f5f6fa;
}

@media (min-width: 900px) {
    .formFieldItem2 {
        padding-top: 8px;
    }
}

@media (max-width: 600px) {
    .formFieldItem2 {
        padding-top: 2px;
    }
}

/** Horizontal fields group container  field container*/
.formHorizontalGroupFields .formFieldContainer {
    flex-direction: column;
}

/** Horizontal fields group container field container input */
.formHorizontalGroupFields .formFieldContainer .formFieldInput {
    margin-left: 0;
    margin-top: 5px;
}
