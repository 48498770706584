.Bills {
  margin-right: 5px;
}

.Providers {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-left: 5px;
}

@media (max-width: 1270px) {
  .SalesTable {
    padding-bottom: 15px;
  }

  .SalesTable2 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media (min-width: 1280px) {
  .SalesTable {
    padding-right: 15px;
  }

  .SalesTable2 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.SalesChart {
  flex-direction: column;
  align-items: center;
  display: flex;
  flex: 1;
}

.ChartContainer {
  width: 270px;
  height: 270px;
  margin-top: 30px !important;
}

.Title {
  width: 100%;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-end;
  font-size: 14px;
  font-weight: normal;
  padding-top: 25px;
}

.SubTitle {
  width: 100%;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.ChartTitle {
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  font-weight: 700;
  font-family: "Goldplay", sans-serif;
  color: #131f3e;
}

.TableRow {
  padding: 15px;
  text-align: center;
}

.MyTable {
  width: 100%;
  border-collapse: collapse;
}

.TableData {
  border-bottom: 2.5px solid #ccc;
}

.ColumnTitle {
  padding: 0px 15px;
  text-align: center;
}
