.two-buttons-container {
    justify-content: flex-end;
    display: flex;
}

@media screen and (max-width: 600px) {
    .two-buttons-container {
        justify-content: center;
        display: flex;
    }
}

.dropdown-container {
    display: flex;
    align-items: center;
    padding: 20px 0;
}

.accounts-button {
    display: flex;
    justify-content: center;
}

/** Settings panel form inputs */
#txtAccount
{
	background-color: #f5f6fa;
	border-radius: 7px;
	height: 32px;
	font-size: 11pt;
	border-style: none;
	font-weight: 200;
    margin-bottom: 30px;
    width: 100%;
    padding: 15px;
}
