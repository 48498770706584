#dashboardWorkspace {
	border: 1px solid #e7e7eb;
	border-radius: 10px;
	flex: 1;
	background-color: #fff;
}

.MuiBox-root {
	padding: 10px !important;
}

.cutomBox {
    padding: 0px !important;
}

#dashboardWorkspace .ui.menu {
	margin-top: 0 !important;
	border-style: none !important;
	width: calc(100% - 1px);
	border-bottom: 1px solid #e7e7eb !important;
}

.dashboardModule {
	height: 470px;
}

.dashboardModule .ag-root-wrapper {
	border-style: none !important;
}

.dashboardModule .ag-header {
	background-color: transparent !important;
}

.dashboardGridColumn .ag-header-cell-label,
.dashboardGridColumnGroup .ag-header-cell-label {
	justify-content: center;
}

.dashboardGridColumnGroup {
	padding: 10px !important;
	background-color: #fff;
}

.dashboardGridColumnGroup .ag-header-group-text {
	font-family: "Goldplay", sans-serif;
	font-weight: 700;
}

.dashboardGridColumn {
	background-color: #f5f6fa;
	border-top-color: #e5e5ea !important;
}

.dashboardGridEmptyColumn {
	border-top-style: none !important;
}

.dashboardGridRow {
	border-style: none !important;
}

.dashboardGridCell {
	border-bottom: 1px solid #e7e7eb !important;
	justify-content: center;
	white-space: initial;
	line-height: 1 !important;
}
.dashboardGridCell2 {
	border-bottom: 1px solid #e7e7eb !important;
	justify-content: center;
	white-space: initial;
	line-height: 1 !important;
	justify-content: flex-end !important;
}

.dashboardGridCell3 {
	border-bottom: 1px solid #e7e7eb !important;
	justify-content: left;
	white-space: initial;
	line-height: 1 !important;
}

.dashboardGridCell .ag-react-container {
	height: 100%;
	display: flex;
}

.dashboardGridCellBusiness {
	justify-content: center;
	white-space: initial;
	line-height: 1 !important;
	text-align: center;
}

.dashboardModule .ag-header-cell,
.dashboardModule .ag-header-group-cell {
	padding: 0 5px;
}

.dashboardGridFirstColum {
	background-color: #fff;
	border-top-color: transparent !important;
	justify-content: flex-start;
	padding-left: 15px !important;
}

.dashboardGridFirstColum .ag-header-cell-label {
	justify-content: flex-start;
}

.dashboardModule .dashboardGridRow .dashboardGridCell:first-child {
	justify-content: flex-start;
}

.TabStyle {
	font-size: 14px;
	color: #131f3e;
	font-weight: bold;
	outline: none;
	text-transform: capitalize;
}

.TabStyle:focus {
	outline: none;
}

.TabsContainer {
	background-color: #f3f3f3;
}

.dashboardBusinessTitles {
    color: #131f3e !important;
}

.dashboardBusiness {
	height: 275px;
}

.dashboardBusiness .ag-root-wrapper {
	border-top-style: solid !important;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}

.inputContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.inputContainer input {
	width: calc(100% - 1px);
	height: 100%;
	border-style: none;
	font-weight: 300;
	font-size: 1.5em;
	color: #131f3e;
	outline: none;
}

/** Amount class */
.amountClass,
.amountClass .ag-header-cell-label {
	justify-content: flex-end !important;
}

/** Text class */
.textClass,
.textClass .ag-header-cell-label {
	justify-content: flex-start !important;
}