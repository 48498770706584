.salesPaper {
    margin-top: 30px;
    border-radius: 10px !important;
    box-shadow: 10px 10px 5px 0px rgba(74, 34, 212, 0.1) !important;
    font-size: 14px !important;
}

@media (min-width: 600px) {
    .salesPaper > :first-child {
        height: 60px !important;
    }
}

.tableBody {
    border: none !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #131f3e !important;
}

.bankCell {
    color: #4a22d4;
    cursor: pointer;
    padding: 7px;
    border-radius: 5px;
    font-weight: 600;
}

.bankCell:hover {
    border-radius: 6px;
    background-color: #e4def9;
}

/* estilo del contenido del grid */
.ag-theme-alpine .ag-row {
    color: #131f3e !important;
    border-color: #e5e5ea;
    font-size: 0.9em !important;
}

.ag-theme-alpine .ag-row-odd {
    background-color: transparent;
}

.ag-theme-alpine .ag-checkbox-input-wrapper::after {
    color: #d1d1d6;
}

/* estilo de las columnas del grid */
.ag-root-wrapper-body.ag-layout-normal {
    font-size: 9pt !important;
    color: #131f3e !important;
}

/* estilo de los label */
label {
    font-size: 12pt;
    color: #131f3e;
    font-weight: bold !important;
}

/* estilo del mení horizontal*/
.dropdown,
.dropleft,
.dropright,
.dropup {
    font-size: 13pt;
    font-weight: bold;
    display: inline-block;
}

/* combobox de la selección del iva/ISRL compras*/
#ddlCompras {
    font-size: 14pt !important;
    font-weight: bold;
    border-radius: 10px;
    color: #4a22d4 !important;
    border-style: solid;
    border-width: 1px;
    border-color: lightgray;
    background-color: white;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    white-space: pre;
}

#ddlCompras:hover {
    color: #131f3e !important;
    white-space: pre;
}

/* combobox de la selección del iva/ISR ventas*/
.ddlVentas > div > span {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    color: #4a22d4 !important;
    font-size: 16px !important;
}

.formRounded {
    background-color: white !important;
}

.formRounded .MuiOutlinedInput-notchedOutline {
    border: 2px solid #4a22d4 !important;
    border-radius: 10px !important;
}

.selectPaper {
    margin-top: 10px !important;
    border-radius: 10px !important;
    box-shadow: 10px 10px 15px 0px rgba(74, 34, 212, 0.3) !important;
}

.selectPaper li {
    background: none !important;
}

.selectPaper li:hover {
    background-color: #faf8fe !important;
}

.selectPaperAdmin {
    border-radius: 10px !important;
    box-shadow: 10px 10px 15px 0px rgba(74, 34, 212, 0.3) !important;
}

/* Center header column */
.centerHeader .ag-header-cell-label {
    justify-content: center;
}

/** Center header filter icon */
.centerHeader .ag-header-cell-menu-button {
    display: none;
}

/* Div botón a la derecha*/
#idItemRight {
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
}

/* Estilo del div botón a la derecha Deshabilitado*/
.idDibvDisabledsmall {
    border-radius: 11px !important;
    background-color: white !important;
    border-color: #e5e5e5 !important;
    border: 1px solid;
    width: 120px !important;
    text-align: center !important;
    color: #e5e5e5 !important;
    height: 30px !important;
    padding-top: 4px;
    font-weight: 100;
}

/* Estilo del div botón a la derecha Habilitado*/
.idDivEnabledSmall {
    border-radius: 11px !important;
    background-color: #131f3e !important; /* Change the value to with anchors hover color*/
    border-color: #131f3e !important;
    border: 1px solid;
    width: 120px !important;
    text-align: center !important;
    color: white !important;
    height: 30px !important;
    padding-top: 4px;
    cursor: pointer;
    font-weight: 100;
}

/* Estilo del div botón a la derecha Habilitado*/
.idDivEnabledSmall:hover {
    background-color: #4a22d4 !important;
    border-color: #4a22d4 !important;
}

/* Estilo del div botón a la derecha Deshabilitado*/
.idDibvDisabled {
    border-radius: 11px !important;
    background-color: white !important;
    border-color: #e5e5e5 !important;
    border-style: solid;
    width: 150px !important;
    text-align: center !important;
    color: #e5e5e5 !important;
    height: 30px !important;
    padding-top: 4px;
    border-width: thin;
}

/* Estilo del div botón a la derecha Habilitado*/
.idDibvEnabled {
    border-radius: 11px !important;
    background-color: #131f3e !important; /* Change the value to with anchors hover color*/
    border-color: #131f3e !important;
    border-style: solid;
    width: 150px !important;
    text-align: center !important;
    color: white !important;
    height: 30px !important;
    padding-top: 4px;
    cursor: pointer;
}

/* Estilo del div botón a la derecha Habilitado*/
.idDibvEnabled:hover {
    background-color: #4a22d4 !important;
    border-color: #4a22d4 !important;
}

/* Estilo del div botón a la derecha*/
#idDibv {
    border-radius: 11px !important;
    background-color: #131f3e;
    width: 120px;
    text-align: center !important;
    color: white !important;
    height: 30px !important;
    padding-top: 6px;
    cursor: pointer;
}
#idDibv:hover {
    background-color: #4a22d4; /* Change the value to with anchors hover color*/
}

/* Títilo de comprobante de ret */
.MuiTypography-h6 {
    font-size: 18px !important;
    font-weight: 600;
    color: #131f3e;
}

/* Grid */
#salesGrid {
    height: 330px;
}

.ui.link.menu .item:hover,
.ui.menu .dropdown.item:hover,
.ui.menu .link.item:hover,
.ui.menu a.item:hover {
    background-color: transparent;
    color: #192145 !important;
}

/* diseño del checkbox del grid cuando se selecciona el elemento */

.ui.menu {
    border: none;
}

/* modifica el alto de la barra de paginación*/
.ag-theme-alpine .ag-paging-panel {
    height: 38px !important;
    border-top-color: #e5e5ea;
}

/* Marca el espacio entre el grid y el menú*/
.ui.menu {
    margin: 1rem 0px 0px 0px !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

/* fileUpload Grid */
.file-upload {
    opacity: 0;
    position: absolute;
    text-align: center;
    cursor: pointer;
    top: 0;
    left: 0;
    height: 75px;
    width: 100%;
}
.file-container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

/* Datepicker ----------------------------------------- */

/* Style to replace some Datepicker (from jQuery UI) styles */
/* Widget general style */
.ui-widget-content {
    color: #6c757d;
}

/* Datepicker second container */
.ui-widget-content {
    border-color: #dee2e6;
}

/* Datepicker month and year name */
.ui-widget-header {
    border-style: none;
    background: transparent;
}

/* Datepicker left arrow to navigate year and month backward */
.ui-icon-circle-triangle-w {
    background-position: -96px 0px;
}

/* Datepicker right arrow to navigate year and month forward */
.ui-icon-circle-triangle-e {
    background-position: -33px 0;
}

/* Datepicker week day name */
.ui-datepicker th {
    font-weight: lighter;
}

/* Datepicker day squares */
.ui-button,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
html .ui-button.ui-state-disabled:active,
html .ui-button.ui-state-disabled:hover {
    border-color: #f2f2f2;
    background: #fcfcfc;
    border-radius: 3px;
    color: #6c757d;
}

.ui-state-hover {
    background-color: #e9ecef !important;
}

/* Datepicker day highlighted */
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
    border-color: #4a22d4;
    background-color: #e6defd !important;
    color: #212529;
}

.grid-cell-centered {
    text-align: center;
    justify-content: center;
}

.grid-cell-cenLeft {
    text-align: center;
    margin-left: 10px;
    justify-content: center;
}

.grid-cell-cenRight {
    text-align: center;
    justify-content: center;
}

.grid-cell-Left {
    margin-left: 2px;
    justify-content: flex-start;
}

.grid-cell-alignRight {
    text-align: right;
    justify-content: flex-end;
}

/* estilo de mes y año en calendar  */
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
    border-color: #e7e7eb;
    color: #212529;
    border-radius: 5px;
    color: #6c757d;
    width: auto;
    margin: 0 5px;
}

.ag-theme-alpine .ag-ltr .ag-header-select-all {
    margin-right: 12px;
    margin-left: 2px;
}
